.view--precision {
  .view {
    &__body-container {
      display: flex;
      flex-direction: column;
      top: 5rem;
      height: calc(100% - 5rem);
    }
  }

  .precision__joystick {
    position: absolute;
    left: 50%;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    background-color: rgba($grey, 0.5);
    border-radius: 50%;
    box-shadow: 0 0 16px rgba($black, 0.5);
    cursor: grabbing;

    &-container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 148px;
    }

    &-inner {
      width: 64px;
      height: 64px;
      background-color: rgba($grey, 0.5);
      border-radius: 50%;
      box-shadow: 0 0 16px rgba($black, 0.5);
    }

    &-arrow-up {
      position: absolute;
      top: 10px;
      left: 50%;
      margin-left: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid black;
    }

    &-arrow-down {
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid black;
    }

    &-arrow-left {
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid black;
    }

    &-arrow-right {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid black;
    }
  }
}

#app.is-mobile {
  .view--precision .precision__joystick {
    cursor: default;
  }
}
