.view--memory {
  .view {
    &__body-container {
      display: flex;
      flex-direction: column;
      top: 5rem;
      height: calc(100% - 5rem);
    }
  }

  .memory__view {
    width: 100%;
    height: 100%;

    &--box {
      text-align: center;
    }

    &-item {
      &--random {
        position: absolute;
        cursor: pointer;
      }

      &--box {
        padding: 0.25rem;
        cursor: pointer;
      }
    }
  }
}

#app.is-mobile {
  .view--memory .memory__view {
    &-item {
      &--random, &--box {
        cursor: default;
      }
    }
  }
}
