.view__footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;

  a {
    text-decoration: none;
  }
}
