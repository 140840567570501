.view__counter {
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 2rem;
  color: $white;
  background-color: #FDDB8C;

  &-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
  }

  &-amount {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
  }
}
