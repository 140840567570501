.summary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  color: $black;
  background-color: $white;

  p > span {
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
