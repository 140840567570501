.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;

  &:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 45%;
    left: 50%;
    width: 20vw;
    height: 20vw;
    margin-top: -10vw;
    margin-left: -10vw;
    border-radius: 50%;
    border-top: 0.2rem solid $brand-primary;
    border-right: 0.4rem solid transparent;
    animation: spinner 1.1s linear infinite;
    -webkit-animation: spinner 1.1s linear infinite;
  }

  &--hidden {
    display: none;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
