.view--match {
  .view {
    &__body-container {
      display: flex;
      flex-direction: column;
      top: 5rem;
      height: calc(100% - 5rem);
    }

    &__quest, &__answers {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
    }

    &__quest {
      width: 98%;
      margin-left: 1%;

      img {
        width: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
        margin: 1rem;
      }
    }

    &__answers {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 50%;
      padding: 0.5rem;
      background-color: #ee527f;
    }

    &__answer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 1rem);
      margin: 0.5rem;
      background-color: $white;
      border-radius: 0.5rem;
      cursor: pointer;

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
      }

      &-image {
        width: 100%;
        height: auto;
        max-height: 100%;
        padding: 1rem;
      }
    }
  }

  &.view--landscape {
    .view {
      &__body-container {
        flex-direction: row;
      }

      &__quest, &__answers {
        height: 100%;
      }
    }
  }
}

@media (orientation: landscape) {
  .view--match .view {
    &__quest, &__answers {
      h1 {
        font-size: 4vw;
      }
    }
  }
}

@media (orientation: portrait) {
  .view--match .view {
    &__quest, &__answers {
      h1 {
        font-size: 9vw;
      }
    }
  }
}
