.view__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 0.1rem solid transparent;

  &-container {
    position: absolute;
    top: 3rem;
    left: 0;
    display: flex;
    width: 100%;
    height: calc(100% - 6rem);
    overflow: hidden;
  }
}
