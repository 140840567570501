@import 'base/index';
@import 'utils/index';
@import 'layout/index';
@import 'components/index';

html {
  font-size: $font-size-root;

  @media only screen and (min-width: 360px) {
    font-size: $font-size-root + 2px;
  }

  @media only screen and (min-width: 400px) {
    font-size: $font-size-root + 4px;
  }
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
}
