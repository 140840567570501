.view--mole {
  .view__body-container {
    top: 5rem;
    height: calc(100% - 5rem);
  }

  canvas {
    cursor: pointer;
  }
}

#app.is-mobile {
  .view--mole canvas {
    cursor: default;
  }
}
