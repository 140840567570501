.view__intro {
  .view__body {
    position: relative;
    justify-content: inherit;
    width: 100%;
    overflow-y: auto;
  }

  &-title, &-image-title {
    margin: 1rem 0;
  }

  &-description {
    margin: 0.5rem 0;
  }

  &-image {
    width: 100%;
  }
}
