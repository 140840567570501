.result {
  .view__body {
    display: block;
    padding: {
      left: 3rem;
      right: 3rem;
    };
    text-align: center;
    overflow-y: auto;
  }

  img {
    max-width: 70%;
  }
}
