.view--fruits {
  .view {
    &__body-container {
      display: flex;
      flex-direction: column;
      top: 5rem;
      height: calc(100% - 5rem);
    }
  }

  .fruits__view {
    &-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 20%;
    }
  }

  @media (orientation: landscape) {
    .fruits__view {
      &-item {
        width: 15%;
      }
    }
  }
}
